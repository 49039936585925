import { proxy, useSnapshot } from "valtio";
import { useOnce } from "../../utils/react/useOnce.ts";
import { calculateSizeInputs, getDefaultSize, type SizeDeps } from "./commonViewModel.ts";
import type { SelectedOs } from "./os/os.ts";
import { usePackages } from "./vmPackages.ts";
import type { VmSizeSelectLoaderProps } from "./VmSizeSelect.tsx";
import { useSizeParams, type SizeValue } from "./vmSizeSelectUtils.ts";

export function useSizeSelect(existing: SizeValue | undefined, initialOs: SelectedOs) {
    const packages = usePackages();
    const params = useSizeParams();

    const store = useOnce(() => {
        const deps = {
            // NB: These are not reactively updated, but that is an edge case (and would require more overcomplicated clamping logic)
            _packages: packages,
            _sizeParams: params,
            _os: initialOs,
            _diskSize: existing?.disks,
        } satisfies SizeDeps;

        return proxy({
            sizes: calculateSizeInputs.call(deps, existing),
            deps,
        });
    });

    function setOs(value: SelectedOs) {
        console.debug("Setting OS", value);

        store.deps._os = value;
        // Reset size after changing OS (in case previous size is not valid for the new OS)
        setSize(getDefaultSize.call(store.deps));
    }

    function setSize(value: SizeValue) {
        store.sizes = calculateSizeInputs.call(store.deps, value);
    }

    const {
        sizes: { size, size_ranges },
        deps: { _os },
    } = useSnapshot(store) as typeof store;

    return {
        size,
        _os,
        setOs,
        props: {
            value: size,
            // TODO: Constraints are duplicated inside the common view model
            constraints: {
                currentDiskSize: existing?.disks,
                // XXX: Since this is only used for package filtering, we should not use the CPU/RAM ratio constraint
                // cpuCount: size.vcpu,
            },
            sliderRanges: size_ranges,
            onChange: setSize,
        } satisfies Partial<VmSizeSelectLoaderProps>,
    };
}
