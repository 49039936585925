import type { VmParameter } from "@warrenio/api-spec/spec.oats.gen";
import { clamp } from "@warrenio/utils/clamp";
import { mustGet } from "@warrenio/utils/collections/mustGet";
import { first, last, round } from "remeda";
import invariant from "tiny-invariant";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import type { VmPriceFields } from "../pricing/resourcePricing.ts";
import type { VmSizePackage } from "./VmSize.types.ts";
import { osIsWindows, type SelectedOs } from "./os/os.ts";
import { parametersByNameAtom } from "./vmParametersQuery.ts";

export type Range = [min: number, max: number];

export interface SizeValue {
    disks: number;
    vcpu: number;
    ram: number;

    isCustom: boolean;
}

/** Valid values for VM's component sizes */
export interface SizeRanges {
    cpu: number[];
    ram: number[];
    ssd: number[];
}

//#region Size parameters

/**
 * VM-relevant constraints (parameters) extracted from the API
 *
 * @see {@link getSizeParams}
 */
export interface VmSizeParams {
    cpu: VmParameter;
    ram: VmParameter;
    ssd: VmParameter;
}

export function getSizeParams(params: Map<string, VmParameter>): VmSizeParams {
    return {
        cpu: mustGet(params, "vcpu", "params"),
        ram: mustGet(params, "ram", "params"),
        ssd: mustGet(params, "disks", "params"),
    };
}

export function useSizeParams() {
    const params = useSuspenseQueryAtom(parametersByNameAtom);
    return getSizeParams(params);
}

//#endregion Size parameters

//#region Packages

/** Check if the selected {@link os} matches a specific {@link field} constraint of the VM {@link pkg | package}. */
export function matchesPackageOsField(
    pkg: VmSizePackage,
    os: SelectedOs,
    field: "os" | "default" | "disabled",
): boolean {
    const osArray = pkg[field];
    if (osArray !== undefined) {
        return osIsWindows(os) ? osArray.includes("win") : osArray.includes("other");
    }
    return false;
}

/** Can return disabled packages, only for UI usage */
export function filterVisibleOsPackages(allPackages: VmSizePackage[], os: SelectedOs): VmSizePackage[] {
    return allPackages.filter((s) => matchesPackageOsField(s, os, "os"));
}

export function filterActiveOsPackages<T extends VmSizePackage>(packages: T[], os: SelectedOs): T[] {
    return packages
        .filter((pkg) => matchesPackageOsField(pkg, os, "os"))
        .filter((pkg) => !matchesPackageOsField(pkg, os, "disabled"));
}

export function getDefaultPackage(activePackages: VmSizePackage[], os: SelectedOs) {
    return activePackages.find((pkg) => matchesPackageOsField(pkg, os, "default")) ?? first(activePackages);
}

const DISK_PACKAGE_SIZE_DIVISORS = [8, 4, 2, 1];
export const SIZE_DISABLED = 0;

export function getDiskPackages(os: SelectedOs, maxSsdSize: number): VmSizePackage[] {
    return DISK_PACKAGE_SIZE_DIVISORS.map(
        (divisor): VmSizePackage => ({
            default: [],
            disabled: [],
            notice: "",
            os: [osIsWindows(os) ? "win" : "other"],
            ram: SIZE_DISABLED,
            cpu: SIZE_DISABLED,
            // Round to nearest 10
            ssd: round(maxSsdSize / divisor, -1),
        }),
    );
}

export function packageToSizeValue(sizeItem: VmSizePackage, isCustom: boolean): SizeValue {
    return {
        vcpu: sizeItem.cpu,
        ram: sizeItem.ram,
        disks: sizeItem.ssd,
        isCustom,
    };
}

//#endregion Packages

//#region Size ranges

export function clampToRangeValues(rangeValues: number[], value: number): number {
    invariant(rangeValues.length > 0, "Range must have at least one value");
    return clamp(value, rangeValues[0], last(rangeValues)!);
}

export function isSizeTooSmall(pkg: VmSizePackage, ranges: SizeRanges, diskOnly = false): boolean {
    const [minRam, _] = getCpuBoundRamRange(pkg.cpu);
    const ratioRestricted = !diskOnly && pkg.ram < minRam;
    const cpuRestricted = !diskOnly && pkg.cpu < ranges.cpu[0];
    const ramRestricted = !diskOnly && pkg.ram < ranges.ram[0];
    const ssdRestricted = pkg.ssd < ranges.ssd[0];
    return ratioRestricted || cpuRestricted || ramRestricted || ssdRestricted;
}

function getRangeInitialLimits(
    params: VmSizeParams,
    sizeComponentKey: keyof SizeRanges,
    os: SelectedOs | undefined,
): Range {
    const sizeComponent = params[sizeComponentKey];
    const osLimit = os ? (sizeComponent.limits ?? []).find((l) => l.os_name === os.os_name) : undefined;
    // If the OS has a specific limit, use it, otherwise use the general one
    const rangeMin = osLimit?.min ?? sizeComponent.min!;
    const rangeMax = osLimit?.max ?? sizeComponent.max!;
    return [rangeMin, rangeMax];
}

/** Minimum RAM per CPU in MB */
const RAM_PER_CPU_MIN = 512;
/** Maximum RAM per CPU in MB */
const RAM_PER_CPU_MAX = 8192;

function getCpuBoundRamRange(cpuCount: number): Range {
    return [cpuCount * RAM_PER_CPU_MIN, cpuCount * RAM_PER_CPU_MAX];
}

function generateCpuRange([min, max]: Range) {
    const arr = [];
    for (let value = min; value <= max; ) {
        arr.push(value);

        if (value < 2) {
            value += 1;
        } else {
            value += 2;
        }
    }
    return arr;
}

/**
 * @param auxRange Extra range constraint to apply to the generated RAM values (used for eg. CPU-based limitation)
 */
function generateRamMbRange([min, max]: Range, auxRange: Range | undefined) {
    const auxMin = auxRange?.[0];
    const auxMax = auxRange?.[1];

    const arr = [];
    const finalMax = auxMax != null ? Math.min(max, auxMax) : max;
    for (let value = min; value <= finalMax; ) {
        if (auxMin == null || value >= auxMin) {
            arr.push(value);
        }

        if (value < 2048) {
            value *= 2;
        } else {
            value += 2048;
        }
    }
    return arr;
}

function generateSsdGbRange([min, max]: Range, step = 10) {
    const arr = min < step ? [min] : [];

    const start = min < step ? step : min;
    for (let value = start; value <= max; ) {
        arr.push(value);

        const remainder = value % step;
        if (remainder === 0) {
            value += step;
        } else {
            // NB: Subtract remainder first to avoid floating point errors
            value -= remainder;
            value += step;
        }
    }

    return arr;
}

/** Additional constraints for VM size parameters, depending on use case (eg. resizing existing VMs or disks) */
export interface ExtraSizeConstraints {
    /** Constrains RAM based on CPU count */
    cpuCount?: number;
    currentDiskSize?: number;
}

export function getSizeRanges(
    params: VmSizeParams,
    os: SelectedOs | undefined,
    constraints?: ExtraSizeConstraints,
): SizeRanges {
    const ramLimits = getRangeInitialLimits(params, "ram", os);
    let cpuRamLimits = undefined;

    if (constraints) {
        const { cpuCount } = constraints;

        // Clamp RAM range based on CPU count
        if (cpuCount != null) {
            cpuRamLimits = getCpuBoundRamRange(cpuCount);
        }
    }

    return {
        cpu: generateCpuRange(getRangeInitialLimits(params, "cpu", os)),
        ram: generateRamMbRange(ramLimits, cpuRamLimits),
        ssd: getDiskRanges(params, os, constraints),
    };
}

export function getDiskRanges(
    params: VmSizeParams,
    os: SelectedOs | undefined,
    constraints?: ExtraSizeConstraints,
): number[] {
    let ssdValues = generateSsdGbRange(getRangeInitialLimits(params, "ssd", os));

    if (constraints) {
        const { currentDiskSize } = constraints;
        // Minimum SSD size is the current disk size
        if (currentDiskSize != null) {
            ssdValues = ssdValues.filter((i) => i >= currentDiskSize);
        }
    }

    return ssdValues;
}

//#endregion Size ranges

export function sizeToVmPriceFields(size: SizeValue, isDiskPrimary: boolean) {
    return {
        vcpu: size.vcpu,
        memory: size.ram,
        storage: [{ size: size.disks, primary: isDiskPrimary }],
        status: "running",
    } satisfies Partial<VmPriceFields>;
}

//#region Debugging
export function rangesToString(ranges: SizeRanges) {
    function rangeToS(range: number[]) {
        return `${range[0]}-${last(range)}`;
    }

    return `CPU: ${rangeToS(ranges.cpu)}, RAM: ${rangeToS(ranges.ram)}, SSD: ${rangeToS(ranges.ssd)}`;
}
//#endregion
