import { notNull } from "@warrenio/utils/notNull";
import { isDeepEqual } from "remeda";
import type { IFormModel } from "../../utils/getField.tsx";
import { showError } from "../error/errorStream.ts";
import type { SelectedOs } from "./os/os.ts";
import type { VmSizePackage } from "./VmSize.types.ts";
import {
    clampToRangeValues,
    filterActiveOsPackages,
    getDefaultPackage,
    getSizeRanges,
    isSizeTooSmall,
    packageToSizeValue,
    type SizeRanges,
    type SizeValue,
    type VmSizeParams,
} from "./vmSizeSelectUtils.ts";

export interface SizeInputs {
    size: SizeValue;
    size_ranges: SizeRanges;
}

/** Common data necessary to calculate sizes */
export interface SizeDeps {
    readonly _packages: VmSizePackage[];
    readonly _sizeParams: VmSizeParams;
    readonly _os: SelectedOs;
    readonly _diskSize: number | undefined;
}

/** Common base interface for forms that allow selecting a VM size (eg. virtual machines & services). */
export interface SizeViewModel extends SizeDeps, IFormModel<SizeInputs> {}

function getValidRanges(this: SizeDeps, size: SizeValue | undefined) {
    return getSizeRanges(this._sizeParams, this._os, {
        currentDiskSize: this._diskSize,
        cpuCount: size?.vcpu,
    });
}

function getValidPackages(this: SizeDeps): VmSizePackage[] {
    // NB: For calculating the valid ranges for packages, we disregard the current size (ie. current CPU/RAM ratio is ignored)
    const ranges = getValidRanges.call(this, undefined);
    return filterActiveOsPackages(this._packages, this._os).filter((p) => !isSizeTooSmall(p, ranges));
}

export function calculateSizeInputs(this: SizeDeps, value: SizeValue | undefined): SizeInputs {
    value ??= getDefaultSize.call(this);

    const ranges = getValidRanges.call(this, value);

    // NB: We must clamp the RAM on every change, because the size Slider might queue multiple `onSizeChange` events
    // in one event loop cycle, and all of those will have the old/unclamped `ram` value, not the clamped one.
    const ram = clampToRangeValues(ranges.ram, value.ram);
    if (ram !== value.ram) {
        console.debug("Clamped RAM to range", ram);
        value = { ...value, ram };
    }

    return {
        size: value,
        size_ranges: ranges,
    };
}

export function onSizeChange(this: SizeViewModel, value: SizeValue) {
    // console.debug("onSizeChange, value:", value);
    const { size, size_ranges } = calculateSizeInputs.call(this, value);
    this.set("size", size);
    this.set("size_ranges", size_ranges);
}

export function forceSizeForOs(this: SizeViewModel) {
    const size = notNull(this.get("size"));

    // Find if the existing size is already an available package for the selected OS
    const matchingSize: SizeValue = { ...size, isCustom: false };
    const validPackages = getValidPackages.call(this);

    const matchesSize = validPackages.some((p) => isDeepEqual(packageToSizeValue(p, false), matchingSize));

    // If it is valid, just keep the existing size
    if (matchesSize) {
        return;
    }

    // Otherwise, get the default size for the selected OS
    onSizeChange.call(this, getDefaultSize.call(this));
}

export function getDefaultSize(this: SizeDeps): SizeValue {
    const validPackages = getValidPackages.call(this);
    const os = this._os;
    const defaultPackage = getDefaultPackage(validPackages, os);
    if (!defaultPackage) {
        showError(`No default package found for OS ${JSON.stringify(os)}`);

        // TODO: Should we handle the case where there is no default package for the OS?
        const { ssd, cpu, ram } = this._sizeParams;
        return { disks: ssd.min ?? 20, vcpu: cpu.min ?? 1, ram: ram.min ?? 512, isCustom: true };
    }

    return packageToSizeValue(defaultPackage, false);
}
