import type { VirtualMachineStorage, VmAddDiskBody, VmResizeDiskBody } from "@warrenio/api-spec/spec.oats.gen";
import { useState } from "react";
import { last } from "remeda";
import invariant from "tiny-invariant";
import { FormField } from "../../components/forms/FormField.tsx";
import { FormMaxWidthWrapper } from "../../components/forms/FormMaxWidthWrapper.tsx";
import { RadioGroupDiv } from "../../components/forms/RadioGroupDiv.tsx";
import { WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { VmStopModalHeaderBlock, type VmCommonFields } from "./ModalHeaderBlock.tsx";
import { VmSizeSelect } from "./VmSizeSelect.tsx";
import { extractOsFields } from "./os/os.ts";
import { addDiskMutation, resizeDiskMutation } from "./vmQuery.ts";
import { getDiskPackages, getDiskRanges, SIZE_DISABLED, useSizeParams, type SizeValue } from "./vmSizeSelectUtils.ts";

interface DiskResizeModalProps {
    vm: VmCommonFields;
    /** Selected disk to resize, or null if adding a new disk */
    disk: VirtualMachineStorage | null;

    onAddDisk?: (params: { location: string; body: VmAddDiskBody }) => Promise<void>;
    onResizeDisk: (params: { location: string; body: VmResizeDiskBody }) => Promise<void>;
}

export function DiskAddOrResizeModal(props: Omit<DiskResizeModalProps, "onAddDisk" | "onResizeDisk">) {
    //#region Hooks
    const createMutation = useStandardMutation(addDiskMutation);
    const resizeMutation = useStandardMutation(resizeDiskMutation);
    //#endregion

    return (
        <BaseDiskModal
            {...props}
            onAddDisk={async (params) => {
                await createMutation.mutateAsync(params);
            }}
            onResizeDisk={async (params) => {
                await resizeMutation.mutateAsync(params);
            }}
        />
    );
}

export function BaseDiskModal({ vm, disk, onAddDisk, onResizeDisk }: DiskResizeModalProps) {
    const { status, location, designated_pool_uuid, uuid } = vm;

    invariant(!disk || onAddDisk, "onAddDisk is required when existing disk is not provided");

    //#region Hooks

    const sizeParams = useSizeParams();
    // NB: Operating system is `undefined` because we do not want to enforce any constraints based on the OS when adding non-boot disks (and they are irrelevant when resizing)
    const diskRanges = getDiskRanges(sizeParams, undefined, {
        currentDiskSize: disk?.size,
    });
    const minDiskSize = diskRanges[0];
    const [size, setSize] = useState<SizeValue>({
        disks: diskRanges[0],
        vcpu: SIZE_DISABLED,
        ram: SIZE_DISABLED,
        isCustom: true,
    });
    //#endregion Hooks

    const packages = getDiskPackages(extractOsFields(vm), last(diskRanges)!);

    function handleChangeSize(newValue: SizeValue) {
        setSize(newValue);
    }

    async function onSubmit() {
        if (disk) {
            await onResizeDisk({
                location,
                body: { uuid, disk_uuid: disk.uuid, size_gb: size.disks, check_vm_state: false },
            });
        } else {
            invariant(onAddDisk);
            await onAddDisk({ location, body: { uuid, size_gb: size.disks } });
        }
    }

    return (
        <WModalContent
            title={disk ? "Resize Disk" : "Add Additional Disk"}
            label={disk ? "Resize" : "Add"}
            footerNotice={
                disk && status === "running" && disk.primary
                    ? "Warning: It is recommended to stop the VM before resizing boot disk."
                    : undefined
            }
            modalAction={onSubmit}
        >
            <VmStopModalHeaderBlock vm={vm} showStopButton={!!disk} />

            <FormMaxWidthWrapper>
                <FormField wide label="Size" block isRequired>
                    <RadioGroupDiv>
                        <VmSizeSelect
                            vmData={{ location, designated_pool_uuid, ...extractOsFields(vm) }}
                            sliderRanges={{
                                ssd: diskRanges,
                                cpu: [],
                                ram: [],
                            }}
                            value={size}
                            onChange={handleChangeSize}
                            packages={packages}
                            diskOnly
                            isDiskPrimary={disk ? disk.primary : false}
                        />
                    </RadioGroupDiv>
                </FormField>
            </FormMaxWidthWrapper>

            {disk && (
                <p>
                    Disk size can only be increased. This action is not reversible. Minimum disk size:{" "}
                    <b>{minDiskSize} GB</b>.
                </p>
            )}
        </WModalContent>
    );
}
